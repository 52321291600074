import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/Seo';
import ThreeColCta from '../../../../components/ThreeColCta';

const HorizonApp = () => (
	<Layout>
		<Seo
			title="Download Sony Hearing Control App for Sony hearing aids | hear.com"
			description="Download the Sony Hearing Control App for the Sony CRE-E10, CRE-C10, and CRE-C20 hearing aids. Easy and quick initial setup with step-by-step instructions."
		/>
		<div className="o-wrapper">
			<div className="o-row-2p5">
				<div className="c-newsletter-img">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-hearing-aids-app.png"
						alt="man sitting on couch and smiling"
						loading="lazy"
					/>
				</div>
			</div>
			<div className="o-row-2p5">
				<h1 className="c-content-hero__headline">Download Sony Hearing Control App</h1>
			</div>
			<ul className="c-usps">
				<li>Personalize your hearing aids to your hearing loss </li>
				<li>Control hearing aids remotely via your smartphone </li>
				<li>Adjust hearing aids on the go as needed</li>
			</ul>
			<div className="o-row-2p5">
				<h2 className="c-app-section__title">Click here to Download App</h2>
			</div>
			<div className="c-app-box">
				<a
					className="c-app-box__panel c-app-box__android"
					href="https://play.google.com/store/apps/details?id=jp.co.sony.nb.hearingcontrol&hl=en_US"
				>
					<p className="c-app-box__android-title">Android</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/android-small.png"
						alt=""
					/>
				</a>
				<a className="c-app-box__panel c-app-box__ios" href="https://apps.apple.com/us/app/sony-hearing-control/id1633950748">
					<p className="c-app-box__ios-title">Apple iOS</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/ios-small.png"
						alt=""
					/>
				</a>
			</div>
			<section className="c-app-section">
				<div className="o-row-2p5">
					<p className="c-app-section__title">Sony Hearing Control app</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
                    This app is designed for Sony self-fitting hearing aids, including the CRE-E10, CRE-C10, and CRE-C20. It offers a straightforward and swift setup, along with remote control capabilities guided by clear instructions.  							
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
					Tailored to your hearing: Customize your hearing aids to your specific needs with the self-fitting test available on the Sony | Hearing Control app, ensuring you don't miss a thing. 
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
					Smartphone control: Easily manage your hearing aids with your smartphone. The app allows you to set up your devices, adjust volume, and sound balance (tone). Communication between the hearing aids and your smartphone is handled via acoustic link or Bluetooth.
					</p>
				</div>
			</section>
			<div className="o-row-2p5">
				<p className="c-app-section__title">Resources</p>
			</div>
			<div className="o-row-2p5">
				<ThreeColCta
					ImgSrc1="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg"
					ContentTitle1="Hearing Test"
					ContentCopy1="Check how is your hearing in less than 5 minutes."
					Url1="/hearing-test/"
					ImgSrc2="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg"
					ContentTitle2="Hearing Loss"
					ContentCopy2="There are several types of hearing loss and many different cause."
					Url2="/hearing-loss/"
					ImgSrc3="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg"
					ContentTitle3="Hearing Aids"
					ContentCopy3="Connect you to the world around you with our wide range of devices."
					Url3="/hearing-aids/"
				/>
			</div>
		</div>
	</Layout>
);

export default HorizonApp;
